<template>
  <b-card>
    <div>
      <label class="balance-title">
        {{ $t('views.news.list.publication') }}
      </label>
    </div>
      <b-row>
        <b-col cols="12">
          <label class="field-name-class">
            {{ $t('views.news.list.publication-date') }}
          </label>
        </b-col>
        <b-col cols="12">
          <div
              class="has-calendar"
              @click="displayStartCalendar = !displayStartCalendar"
          >
            <span class="fa clock-class fa-calendar form-control-feedback"/>
            <b-form-input
                :value="news.publicationStartDate ? $toTimezone(news.publicationStartDate).format('DD-MM-YYYY') : $t('general.actions.undefined')"
                size="sm"
                class="background-light-blue-inputs pointer"
            />
          </div>
          <d-calendar-modal
              :show="displayStartCalendar"
              :identifier="'modal-start-calendar'"
              @on:calendar-change="onChangedStartDate"
          />
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <label class="field-name-class">
            {{ $t('views.news.list.publication-end') }}
          </label>
        </b-col>
        <b-col cols="12">
          <div
              class="has-calendar"
              @click="displayEndCalendar = !displayEndCalendar"
          >
            <span class="fa clock-class fa-calendar form-control-feedback"/>
            <b-form-input
                :value="news.publicationEndDate ? $toTimezone(news.publicationEndDate).format('DD-MM-YYYY') : $t('general.actions.undefined')"
                size="sm"
                class="background-light-blue-inputs pointer"
            />
          </div>
          <d-calendar-modal
              :show="displayEndCalendar"
              :identifier="'modal-end-calendar'"
              :filter-from-date="getFilterDate"
              @on:calendar-change="onChangedEndDate"
          />
        </b-col>
      </b-row>
    <b-row class="mt-3" align="center">
      <b-col>
        <d-button
            text="general.actions.save"
            class="d-btn-sm font-text-title d-btn btn d-btn-danger font-text-title"
            @on:button-click="$emit('on:save')"
        />
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
  export default {
    props: {
      news: {
        type: Object,
        default: this
      }
    },
    data: () => ({
      displayStartCalendar: false,
      displayEndCalendar: false,
    }),
    computed: {
      getFilterDate() {
        return this.$toTimezone(this.news.publicationStartDate);
      }
    },
    methods: {
      onChangedStartDate (startDate) {
        this.news.publicationStartDate = this.$toTimezone(startDate);
      },
      onChangedEndDate (publicationEndDate) {
        this.news.publicationEndDate = this.$toTimezone(publicationEndDate);
      }
    }
  }
</script>

<style lang="scss" scoped>
.img-margins {
  margin-top: 20%;
}
@import "@lazy/_b-card.scss";
.card .card-body{
  padding-left: 25px;
  padding-right: 25px;
}
.product-box .product-img .product-hover {
  height: 145px;
  width: 145px;
}
.balance-title {
  text-align: left;
  font: normal normal 900 18px Avenir;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}
</style>


