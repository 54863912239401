<template>
  <validation-observer
    slim
    ref="newsObserver"
  >
    <div v-if="news">
      <d-body-top-bar
        :title="$route.params.id ? $t('views.news.list.edit-act') : $t('views.news.list.new')"
      >
        <div slot="tree">
          <label
            class="pointer inactive-tree link-area ml-1"
            @click="$router.push({ name: 'news'})"
          >
            {{ $t('views.news.all-news') }}
          </label> /
          <label class="bg-doinsport-primary ml-1">
            {{ actualityLabel }}
          </label>
        </div>
      </d-body-top-bar>
      <div class="container-fluid">
        <b-row>
          <b-col cols="9">
            <b-card>
              <div>
                <label class="balance-title">
                  {{ $t('views.news.list.general-information') }}
                </label>
              </div>
              <b-row class="mb-2">
                <b-col cols="12">
                  <label class="field-name-class">
                    {{ $t('views.news.list.title') }}
                  </label>
                  <d-text-field
                    v-model="news.title"
                    :rules="validation.title"
                    class-name="background-light-blue-inputs"
                    error-text="validation.text-input-three-characters"
                    size="sm"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <label class="field-name-class">
                    {{ $t('views.news.list.content') }}
                  </label>
                </b-col>
                <b-col cols="12">
                  <email-editor
                    :is-empty="true"
                    :default-content="news.content"
                    @on:editor-content:update="onEditorContentUpdate"
                  />
                </b-col>
              </b-row>
            </b-card>
            <b-card>
              <div>
                <label class="balance-title">
                  {{ $t('views.news.list.resume') }}
                </label>
              </div>
              <b-row>
                <b-col cols="12">
                  <label class="field-name-class">
                    {{ $t('views.news.list.short-description') }}
                  </label>
                </b-col>
                <b-col cols="12">
                  <b-textarea
                    v-model="news.shortContent"
                    rows="5"
                    size="sm"
                    no-resize
                    maxlength="100"
                    minlength="2"
                    class="background-light-blue-inputs"
                    :state="count == 0 ? true : 'false'"
                  />
                  <b-form-invalid-feedback
                    :state="!isRequired"
                  >
                    {{ $t('views.news.list.short-content-validator') }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="3">
            <publication
              :news="news"
              @on:save="onSave"
            />
            <preview
              :news="news"
            />
            <gallery/>
          </b-col>
        </b-row>
      </div>
    </div>
  </validation-observer>
</template>
<script>
import EmailEditor from "@custom/EmailEditor";
import Preview from "./Preview";
import Publication from "@views/news/createOrUpdate/Publication";
import News from "@/classes/doinsport/News";
import {getClubNewsModel, postClubNews, putClubNews} from "@api/doinsport/services/news/news.api";

export default {
  components: {Publication, Preview, EmailEditor},
  data: () => ({
    news: null,
    isRequired: false,
    validation: require('@validation/entities/doinsport/News.json')
  }),
  computed: {
    count() {
      if (this.news.shortContent) {
        return 100 - this.news.shortContent.length;
      } else {
        return 100;
      }
    },
    actualityLabel() {
      return this.$route.params.id ? this.news.title : this.$t('views.news.list.new-act');
    }
  },
  methods: {
    onSave() {
      if (this.news.shortContent && this.news.shortContent.length < 2 && this.news.shortContent !== '') {
        this.isRequired = true;
      } else {
        this.news.publicationStartDate = this.$toUtc(this.news.publicationStartDate);
        this.news.publicationEndDate = this.$toUtc(this.news.publicationEndDate);

        if (this.news.shortContent !== null) {
          if (this.news.shortContent.trim().length === 0) {
            this.news.shortContent = null;
          }
        }
        const blobPhoto = this.news.mainPhoto;
        delete this.news.mainPhoto;
        if (blobPhoto) {
          if (this.news.id === null) {
            postClubNews(this.news)
              .then((response) => {
                const payload = {
                  entry: 'news',
                  url: response.data['@id'],
                  target: 'postClubNewsPhoto',
                  position: 0
                };
                this.$uploadFile(blobPhoto, payload);
                this.$router.push({name: 'news'});
              })
              .catch(() => {
                this.news.mainPhoto = blobPhoto;
              })
            ;
          } else {
            putClubNews(this.news.id, this.news)
              .then((response) => {
                if ("undefined" === typeof (blobPhoto.contentUrl)) {
                  const payload = {entry: 'news', url: response.data['@id'], target: 'postClubNewsPhoto', position: 0};
                  this.$uploadFile(blobPhoto, payload);
                }
                this.$router.push({name: 'news'});
              })
              .catch(() => {
                this.news.mainPhoto = blobPhoto;
              })
            ;
          }
        } else {
          this.$flash(this.$t('general.actions.error'), this.$t('views.news.list.file-required'));
        }
      }
    },
    onEditorContentUpdate(content) {
      this.news.content = content;
    }
  },
  created() {
    if (this.$route.params.id) {
      getClubNewsModel(this.$route.params.id)
        .then((response) => {
          this.news = new News(response.data, {serialize: true});
        })
      ;
    } else {
      this.news = new News();
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_b-card.scss";

.card .card-body {
  padding-left: 25px;
  padding-right: 25px;
}

.balance-title {
  text-align: left;
  font: normal normal 900 18px Avenir;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

/deep/ .ql-editor {
  max-height: unset;
  min-height: 200px;
}
</style>
