<template>
  <b-card>
    <div>
      <label class="balance-title">
        {{ $t('views.news.list.preview') }}
      </label>
    </div>
    <div class="d-flex justify-content-center">
      <div class="product-box mt-2">
        <div class="product-img">
          <img v-if="fileToken === null"
               class="pointer"
               height="95"
               width="95"
               src="@/assets/plus.svg"
               @click="uploadPhoto"
               alt
          />
          <img v-else
               :src="fileToken"
               class="object-fit-img"
               height="100%"
               width="100%"
               alt
          />
          <div
              v-if="fileToken"
              class="product-hover"
          >
            <ul>
              <li
                  @click="resetPhoto"
                  class="pointer"

              >
                <button class="btn" type="button">
                  <i class="icon-trash"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>
<script>
import {deleteClubNewsPhoto} from "@api/doinsport/services/news/news.api";

export default {
  data: () => ({
    fileToken: null
  }),
  props: {
    news: {
      type: Object,
      default: this,
    }
  },
  methods: {
    uploadPhoto() {
      this.$upload(this.uploaded, {entry: 'news', url: null, target: ''});
    },
    uploaded(file) {
      const url = URL.createObjectURL(file);
      this.fileToken = url;
      this.news.mainPhoto = file;
    },
    resetPhoto() {
      this.fileToken = null;
      if (this.news.id && this.news.mainPhoto) {
        deleteClubNewsPhoto(this.news.mainPhoto.id)
        this.news.mainPhoto = null;
      }
    },
  },
  created() {
      if (this.news.mainPhoto) {
        this.fileToken = this.$filePath(this.news.mainPhoto.contentUrl);
      }
  }
}
</script>

<style lang="scss" scoped>
.img-margins {
  margin-top: 20%;
}

@import "@lazy/_b-card.scss";
.card .card-body {
  padding-left: 25px;
  padding-right: 25px;
}

.product-box .product-img .product-hover {
  height: 100%;
  width: 100%;
}

.balance-title {
  text-align: left;
  font: normal normal 900 18px Avenir;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}
</style>


